.status-indicators {
    display: flex;
    flex-wrap: wrap;
    border: inset 3px;
    background-color: black;
}

.status-indicators img {
    border-color: #fff8ff #12225f #000 lightgray;
    border-style: outset;
    border-width: 3px;

    outline: solid black 1px;

    max-width: 100px;
    max-height: 40px;
}

.status-indicators .updated {
    animation: updatedimg 2.5s forwards 1 linear;
}

@keyframes updatedimg {
    0% {
        filter: brightness(2.5);
    } 100% {
        filter: brightness(1);
    }
}

.status-indicators .off {
    animation: offimg 2.5s forwards 1 linear;
}

@keyframes offimg {
    0% {
        filter: brightness(0.5);
    } 100% {
        filter: brightness(1);
    }
}

.status-indicators-control {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 25px;
}

.status-light-div p {
    color: white;
    font-size: 9px;
    text-align: center;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
}


.scrolling-text-div.updated {
    animation: scrollerupdate 2.5s forwards 1 linear;
}

@keyframes scrollerupdate {
    0% {
        background: gray;
    } 100% {
        background: #04061a;
        background-image: url(/public/images/bgs/BlackThatch.png);
    }
}