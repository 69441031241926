.left-section {
    display: flex;
    align-items: center;
    overflow: clip;
}

.left-box {
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: 2px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
}

.left-buttons {
    display: flex;
    height: 100%;
}

.left-buttons img {
    pointer-events: none;
    transform: rotate(90deg);
}

.left-buttons button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: bold;
    font-variant-caps: small-caps;
    text-shadow: 1px 1px 0px rgb(164, 0, 0);
    writing-mode: vertical-lr;
    text-orientation: sideways;
}

.left-buttons button, .past-expand {
    background-image: url('/public/images/bgs/playv.png');
    background-size: 100% 100%;
    border-style: outset;
    border-width: 0px;
    height: 25%;
    image-rendering: pixelated;
    outline: 1px solid #000;
    padding: 0;
    cursor: pointer;
}

.past-expand {
    display: flex;
    align-items: center;
    background-image: url('/public/images/bgs/play.png') !important;
}

.left-buttons button:hover {
    filter: brightness(1.1);
    cursor: pointer;
}

.left-buttons button:active {
    background-image: url('/public/images/bgs/playv-indent.png');
    text-shadow: 1px 1px 0px black;
    color: white;
    filter: brightness(0.9);
}

.left-viewer {
    background-color: black;
    height: 85%;
    width: 60vw;

    background: #04061a;
    border-bottom: solid 3px #dadbe8;
    border-right: solid 3px lightgray;
    border-left: solid 3px gray;
    border-top: solid 3px darkgray;
    outline: solid 2px black;

    margin: 5px;

    display: grid;
    grid-template-rows: 12px calc(100% - 12px);

    transition: 0.3s ease;
    margin-left: -100vw;
}

.viewer-expanded {
    margin-left: calc(0vw + 40px);
}

.past-container {
    margin-top: 6px;
    max-height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    margin-right: -1px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 8px;
}

.past-container button {
    padding-left: 4px;
}

.p-grid {
    border-bottom: solid 2px #dadbe8;
    outline: solid 2px black;
    padding: 0 2px 2px;
    max-width: fit-content;
    max-width: 48%;
    transform: scale(0.98);
}

.p-project {
    display: grid;
    grid-template-columns: 20% 50% 30%;
    padding: 6px;
}

.past-titles {
    color: #32a852;
    text-align: center;
}

.past-yeartag {
    color: white;
    text-align: center;
    background-color: black;
}

.past-h4 {
    margin: 8px;
    background: #04061a;
    border-bottom: solid 3px #dadbe8;
    border-right: solid 3px lightgray;
    border-left: solid 3px gray;
    border-top: solid 3px darkgray;
    outline: solid 2px black;
    color: white;
    font-size: 18px;
    text-align: center;
}

.past-desc {
    color: white;
    padding: 2px;
    margin: 0;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    text-shadow: 0 0 4px black;
    background-color: rgba(0,0,0,0.4);
}


.map-details {
    display: grid;
    grid-template-columns: 33% 34% 33%;
    grid-template-rows: 14px 0px 0px 0px 0px 0px 0px 0px 0px;
    align-items: center;
    justify-content: center;

    height: 65px;
    width: 100%;
    max-width: 205px;

    margin-top: -67px;
    z-index: 3;
    position: absolute;
    right: 0;
    top: 72px;

    transition: 0.3s ease;
}

.map-details div {
    opacity: 0;
    pointer-events: none;
}

.map-details.control-show {
    grid-template-rows: 14px 40px 25px 25px 25px 25px 25px 25px 25px 0px;
}

.map-details.control-show div {
    opacity: 1;
    pointer-events: all;
}

.map-details.key-show {
    grid-template-rows: 14px 0px 0px 0px 0px 0px 0px 0px 0px 20px;
}

.map-details.control-show.key-show {
    grid-template-rows: 14px 40px 25px 25px 25px 25px 25px 25px 25px 20px;
}

.map-details p {
    font-weight: bold;
    font-variant-caps: small-caps;
    margin: 0;
    color: black;
    font-size: 14px;
}

.hanging-buttons {
    opacity: 0.3;
    transition: 0.1s ease;
}

.hanging-buttons:hover {
    opacity: 0.7;
}

.zindex-501 {
    z-index: 501;
}

.zindex-502 {
    z-index: 502;
}

.zindex-503 {
    z-index: 503;
}

.car-details {
    display: grid;
    grid-template-columns: 33% 34% 33%;
    grid-template-rows: 30px 35px;
    align-items: center;
    justify-content: center;

    height: 26px;
    width: 52px;

    margin-top: -67px;
    z-index: 3;
    position: absolute;
    left: 0;
    bottom: 0;
}

.leaflet-popup-content {
    font-family: ms ui gothic;
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
    background-color: black;
    border: inset 3px;
    padding: 3px;
    color: lime;
    text-shadow: 0 0 3px lime;
    font-size: 12px;
}

.leaflet-popup-tip {
    background:-webkit-repeating-linear-gradient(315deg, #000000 0%, #50508b 1%);
    background:-o-repeating-linear-gradient(315deg, #000000 0%, #50508b 1%);
    background:-moz-repeating-linear-gradient(315deg, #000000 0%, #50508b 1%);
    background:repeating-linear-gradient(315deg, #000000 0%, #50508b 1%);
    outline: solid lightblue 1px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out, .leaflet-popup-content-wrapper {
    background:-webkit-repeating-linear-gradient(0deg, #000000 0%, #50508b 1%);
    background:-o-repeating-linear-gradient(0deg, #000000 0%, #50508b 1%);
    background:-moz-repeating-linear-gradient(0deg, #000000 0%, #50508b 1%);
    background:repeating-linear-gradient(0deg, #000000 0%, #50508b 1%);
    outline: solid lightblue 1px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
    font: 18px 'ms ui gothic';
}

.leaflet-control-zoom-in:hover, .leaflet-control-zoom-out:hover {
    outline: solid cyan 1px;
}

.leaflet-control-zoom-in:hover span, .leaflet-control-zoom-out:hover span {
    color: cyan;
}

.leaflet-control-zoom-in:active, .leaflet-control-zoom-out:active {
    outline: solid lightblue 2px;
    outline-offset: -1px;
}

.leaflet-control-zoom-in:active span, .leaflet-control-zoom-out:active span {
    color: lightblue;
}

.leaflet-control-zoom-in span, .leaflet-control-zoom-out span {
    color: lightblue;
}

.leaflet-container .leaflet-control-attribution {
    background-color: rgba(0,0,0,0.6);
}

.leaflet-control-attribution.leaflet-control a, .leaflet-control-attribution.leaflet-control span {
    font-family: ms ui gothic;
    color: lightblue;
}

.highlight-polygon {
    animation: hipoly 10s ease forwards;
}

@keyframes hipoly {
    0% {
        fill: url(#repeat);
        fill-opacity: 0.9;
    } 100% {
        fill: transparent;
        fill-opacity: 0;
    }
}

.hidden {
    display: none;
}

.leaflet-layer {
  filter: invert(100%) hue-rotate(180deg) contrast(1.1);
}

.left-viewer .leaflet-pane {
    transform: translateY(-100px);
}

.sliding-expanded .left-viewer .leaflet-pane, .leaflet-pane.leaflet-popup-pane {
    transform: translateY(0px);
}

.leaflet-marker-icon {
    display: none;
}

.leaflet-marker-icon.hidden {
    display: flex;
}

.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
    opacity: 0.5;
    transition: 0.1s linear;
}

path.leaflet-interactive {
    opacity: 0.6;
    transition: 0.1s linear;
}

path.leaflet-interactive:hover {
    opacity: 1;
}

.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive:hover {
    opacity: 1;
}

.no-invert {
    filter: invert(0%) hue-rotate(0deg) contrast(1.5);
}

.map-menu-button {
    border-style: outset;
    border-width: 3px;
    border-color:rgb(182, 255, 182) rgb(135, 153, 135) rgb(135, 153, 135) rgb(182, 255, 182);
    background: rgb(165 242 165);
    width: 56px;
    height: 25px;
    margin: 2px;
}

.map-menu-button:hover {
    filter: brightness(1.05);
}

.map-menu-button:active {
    border-style: inset;
    border-color:rgb(135, 153, 135) rgb(182, 255, 182) rgb(182, 255, 182) rgb(135, 153, 135);
    background: rgb(136, 157, 136);
}


.warn-container {
    max-height: 100%;
    overflow: scroll;
    z-index: 1;
}

.warncontainer {
    display: grid;
    grid-template-columns: 33% 34% 33%;
}

.warncontainer h2 {
    font-size: 17px;
}

.filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: outset 3px;
    border-color: #50c750 #0f660f #0f660f #50c750;
    outline: solid black 1px;
    background-color: green;
    margin: 0 3px;
    color: white;
    cursor: pointer;
}

.contact-full {
    display: grid; 
    grid-template-columns: 25% 75%;
}

.contact-full a:visited, .settings-full a:visited {
    color: rgb(180, 141, 215);
    text-shadow: 0 0 3px rgb(180, 141, 215);
}

.faq-box {
    border: inset 3px;
    background-color: black;
    background-image: url(/public/images/bgs/BlackThatch.png);
    margin: 7px 15px;
    padding: 7px;
    color: lime;
    text-shadow: 0 0 3px lime;
}

.faq-box h4 {
    text-decoration: underline;
    margin: 0;
}



.settings-full {
    color: white;
    text-shadow: 0 0 3px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
}

.settings-full a {
    background-color: black;
    padding: 3px;
    border: inset 3px;
    display: flex;
    align-items: center;
}

.left-expand {
    width: 28px;
    height: 28px;
}

.social-container {
    display: grid;
    grid-template-columns: 50% 50%;
}

.flex-list {
    display: flex;
}

@media screen and (max-width: 700px) {
    .left-viewer {
        margin-left: -200vw;
    }

    .viewer-expanded {
        margin-left: calc(-100vw + 60px);
        width: 81%;
    }

    .left-box {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .left-buttons button {
        width: 100%;
    }

    .expand-button {
        display: none;
    }

    .leaflet-pane {
        transform: translateY(0px);
    }

    .contact-full {
        grid-template-columns: 100%;
    }

    .warncontainer {
        grid-template-columns: 50% 50%;
    }

    .p-grid {
        max-width: 95%;
    }

    .social-container {
        grid-template-columns: 100%;
    }
    
}

@media screen and (min-width: 1750px) {
    .left-box {
        grid-template-rows: 50px 2px calc(100% - 72px);
    }

    .left-buttons button {
        font-size: 24px;
    }

    .left-expand {
        width: 42px;
        height: 42px;
    }
}