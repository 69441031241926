h1, h2, h3, h4, h5, p, textarea, input, button, a, b, i, label, legend {
    font-family: ms ui gothic;
}

.App {
    height: calc(100vh - 20px);
    width: 100%;
}

.main-container {
    height: calc(100% - 12px);
    width: calc(100% - 9px);

    display: grid;
    align-items: center;
    gap: 3px;

    grid-template-columns: 260px 40px calc(100% - 300px);
    grid-template-rows: calc(10% - 5px) 5px 40% 20% 5px calc(30% - 5px);
}

.logo-container {
    display: flex; 
    align-items: center; 
    justify-content: center;
    background: #04061a;
    background-image: url(/public/images/bgs/BlackThatch.png);
    border-bottom: solid 3px darkgray;
    height: 100%;
    background-color: black;
    overflow: hidden;
    max-height: 140px;
}

.scrolling-text-div {
    background: #04061a;
    background-image: url(/public/images/bgs/BlackThatch.png);
    border-top: solid 3px #dadbe8;
    border-left: solid 3px lightgray;
    border-right: solid 3px gray;
    border-bottom: solid 3px darkgray;
    outline: solid 2px black;
    outline-offset: -1px;

    overflow: hidden;
}

.scroller {
    display: flex;
    
    width: max-content;
    will-change: transform;
    animation: scrollingtext 20s forwards linear infinite;
    transition: 3s ease;
}

@keyframes scrollingtext {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    } 50% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    } 50.01% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
}

.scrolling-text {
    color: red;
    text-shadow: 0 0 3px red;

    padding: 0px 3px;
    margin: 0px 12px;

    font-weight: 10;
    font-size: 46px;
    line-height: 20px;
    
    white-space: nowrap;
    text-align: center;
}

.scrolling-text i {
    font-family: 'dotty';
}

.feeds-container {
    background: #04061a;
    background-image: url('/public/images/bgs/logo-bg.png');
    background-size: 50px;
    border-top: solid 3px #dadbe8;
    border-left: solid 3px lightgray;
    border-right: solid 3px gray;
    border-bottom: solid 3px darkgray;
    outline: solid 2px black;
    outline-offset: -1px;
    height: 100%;
    margin-top: 5px;

    height: calc(100% - 6px);
    transition: 0.3s ease;
}

.sliding-expanded {
    grid-template-rows: 33% 67%; 
}

.description-section {
    background: #04061a;
    background-image: url(/public/images/bgs/snowbackground.gif);
    border-top: solid 4px #dadbe8;
    border-left: solid 3px lightgray;
    border-right: solid 3px gray;
    border-bottom: solid 3px darkgray;
    outline: solid 2px black;
    outline-offset: -1px;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30% 20% 50%;
    justify-items: center;
    align-items: center;
    height: 100%;
}

.static-text {
    color: red;

    margin: 0px;
    font-size: 12px;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description-qrd h4 {
    color: white;
    margin: 3px;
}

.description-full {
    color: white;
}

.description-banner {
    width: calc(100% - 9px);
    height: 18px;
    margin: 2px;
    padding: 2px;
    
    color: white;
    background: linear-gradient(90deg, rgba(0,0,255,1) 0%, rgba(0,193,255,1) 100%);

    border-bottom: solid 2px white;
    outline: solid 2px black;

    display: flex;
    align-items: center;
}


.chat-section {
    display: flex;
    flex-direction: column; 
    height: calc(100% - 6px);

    background: black; 

    outline: black 2px solid;
    outline-offset: -1px;

    border-color: #000 #fff8ff #fff8ff #000;
    border-style: outset;
    border-width: 3px;
}




::-webkit-scrollbar {
    height: 5px;
    width: 8px;
}

::-webkit-scrollbar-track {
    background: darkgray;
    outline: solid black 1px;
    outline-offset: -1px;
}

::-webkit-scrollbar-thumb {
    background-color: lightgray; 
    transition: 0.2s ease;
    border: outset 2px;
    background-color: lightgray;
    border-right-color: black;
    border-bottom-color: black;
    border-top-color: #FFF8FF;
    border-left-color: #FFF8FF;
    outline: 1px black solid;
    outline-offset: -1px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: darkgray;
}

::-webkit-scrollbar-corner {
    background-image: url('/public/images/bgs/arrowdown.png');
    background-color: darkgray;
    outline: solid black 1px;
}


.stream-container::-webkit-scrollbar {
    width: 16px;
}

.stream-container::-webkit-scrollbar-track, .stream-container::-webkit-scrollbar-button:single-button {
    background:-webkit-repeating-linear-gradient(0deg, #000000 0%, #50508b 1%);
    background:-o-repeating-linear-gradient(0deg, #000000 0%, #50508b 1%);
    background:-moz-repeating-linear-gradient(0deg, #000000 0%, #50508b 1%);
    background:repeating-linear-gradient(0deg, #000000 0%, #50508b 1%);
    border-radius: 2px;
    display: block;
    height: 16px;
    width: 16px;
    cursor: pointer;
    outline: solid black 1px;
    outline-offset: -1px;
}

.stream-container::-webkit-scrollbar-button:single-button:vertical:increment, .stream-container::-webkit-scrollbar-button:single-button:vertical:decrement, .message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:increment, .message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:decrement {
    outline: 1px lightblue solid;
    outline-offset: -1px;
    border: solid 2px transparent;
}

.stream-container::-webkit-scrollbar-button:single-button:vertical:increment:hover, .stream-container::-webkit-scrollbar-button:single-button:vertical:decrement:hover, .message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:increment:hover, .message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    outline: 1px cyan solid;
}

.stream-container::-webkit-scrollbar-button:single-button:vertical:increment:active, .stream-container::-webkit-scrollbar-button:single-button:vertical:decrement:active, .message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:increment:active, .message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    outline: 2px lightblue solid;
    outline-offset: -2px;
}

.stream-container::-webkit-scrollbar-thumb, .message-container-wrapper::-webkit-scrollbar-thumb, .stream-list::-webkit-scrollbar-thumb, .thick-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.1);
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
    outline: solid 1px lightblue;
    outline-offset: -2px;
    border-radius: 3px;
    border: none;
}

.stream-container::-webkit-scrollbar-thumb:hover, .message-container-wrapper::-webkit-scrollbar-thumb:hover, .stream-list::-webkit-scrollbar-thumb:hover, .thick-scroller::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255,255,255,0.2);
}

.stream-container::-webkit-scrollbar-thumb:active, .message-container-wrapper::-webkit-scrollbar-thumb:active, .stream-list::-webkit-scrollbar-thumb:active, .thick-scroller::-webkit-scrollbar-thumb:active {
    outline-width: 2px;
    outline-offset: -3px;
}

.message-container-wrapper::-webkit-scrollbar, .stream-list::-webkit-scrollbar {
    width: 8px;
}

.thick-scroller::-webkit-scrollbar {
    height: 12px;
}

.thick-scroller::-webkit-scrollbar-track {
    background:-webkit-repeating-linear-gradient(90deg, #000000 0%, #004212 0.75%);
    background:-o-repeating-linear-gradient(90deg, #000000 0%, #004212 0.75%);
    background:-moz-repeating-linear-gradient(90deg, #000000 0%, #004212 0.75%);
    background:repeating-linear-gradient(90deg, #000000 0%, #004212 0.75%);
    border-radius: 2px;
    height: 12px;
    outline: solid black 1px;
    outline-offset: -1px;
}

.message-container-wrapper::-webkit-scrollbar-track, .message-container-wrapper::-webkit-scrollbar-button:single-button, .stream-list::-webkit-scrollbar-track, .stream-list::-webkit-scrollbar-button:single-button {
    background:-webkit-repeating-linear-gradient(0deg, #000000 0%, #004212 1%);
    background:-o-repeating-linear-gradient(0deg, #000000 0%, #004212 1%);
    background:-moz-repeating-linear-gradient(0deg, #000000 0%, #004212 1%);
    background:repeating-linear-gradient(0deg, #000000 0%, #004212 1%);
    border-radius: 2px;
    display: block;
    height: 8px;
    width: 8px;
    cursor: pointer;
    outline: solid black 1px;
    outline-offset: -1px;
}

.message-container-wrapper::-webkit-scrollbar-thumb, .stream-list::-webkit-scrollbar-thumb, .thick-scroller::-webkit-scrollbar-thumb {
    outline: solid 1px lime;
}

.message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:increment, .message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:decrement, .stream-list::-webkit-scrollbar-button:single-button:vertical:increment, .stream-list::-webkit-scrollbar-button:single-button:vertical:decrement {
    outline: 1px lime solid;
    outline-offset: -1px;
    border: solid 2px transparent;
}

.message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:increment:hover, .message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:decrement:hover, .stream-list::-webkit-scrollbar-button:single-button:vertical:increment:hover, .stream-list::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    outline: 2px green solid;
}

.message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:increment:active, .message-container-wrapper::-webkit-scrollbar-button:single-button:vertical:decrement:active, .stream-list::-webkit-scrollbar-button:single-button:vertical:increment:active, .stream-list::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    outline: 2px lime solid;
}

.chat-full-container {
    height: calc(90% - 18px);
    overflow: hidden;
}

.left-button-icon {
    width: 22px;
}

.stream-status-light {
    height: 16px;
    width: 16px;
}

.tutorial-text {
    font-size: 17px;
}

.chat-leftpanel-button {
    display: none !important;
}

.account-leftpanel-button {
    display: flex !important;
}

.empty-stream-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty-stream-container p {
    color: white;
    opacity: 0.4;
    background: rgba(0,0,0,0.4);
    border-radius: 5px;
    padding: 5px;
    pointer-events: none;
}

.invert-img {
    transform: rotateY(180deg) rotateX(180deg);
}

.op-low {
    opacity: 0.2;
}

.op-low:hover {
    opacity: 0.9;
}

.stopwatch {
    animation: stopwatch 8s 0s ease forwards;
    position: absolute;
    pointer-events: none;
    z-index: 1000;
    filter: sepia(1);
}

.top-header {
    width: calc(100% - 5px);
    grid-column: span 2;
    grid-row: 1;
}

.qrd-section {
    grid-column: 1;
    grid-row-start: 4;
    grid-row-end: 7;
}

.description-section {
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 4;
}

.feeds-container {
    grid-column: 3;
    grid-row-start: 2;
    grid-row-end: 7;
}

.left-box {
    grid-row-start: 2;
    grid-row-end: 7;
    grid-column: 2;
}

.stream-title-container, .group-stream-name {
    width: 100%;
}

@keyframes stopwatch {
    0% {
        opacity: 1;
        transform: scale(1) translateX(0) translateY(0);
    } 80% {
        opacity: 0.8;
        transform: scale(1.85) translateX(50) translateY(50);
    } 100% {
        opacity: 0;
        transform: scale(2) translateX(70) translateY(70);
    }
}


@media screen and (max-width: 1050px) {
    .group-stream-box {
        scale: 0.75;
    }

    .smallscreen-hide {
        display: none;
    }
}

@media screen and (max-width: 850px) {
    .group-stream-box {
        scale: 0.65;
    }
}


@media screen and (max-width: 700px) {
    .main-container {
        height: 200vh;
        width: 100%;
    
        display: grid;
        align-items: center;

        grid-template-columns: calc(100% - 40px) 40px;
        grid-template-rows: 40px calc(40% - 40px) 30% 30%;
    }

    .feeds-container {
        display: flex; 
        flex-direction: column; 
        height: calc(200% - 6px);
        transition: 0.3s ease;
        width: 100%;
        height: 100%;

        grid-column: 1;
        grid-row: 2;
    }

    .top-header {
        width: 100%;
        grid-column: span 2;
        grid-row: 1;
    }

    .left-box {
        grid-column: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .description-section {
        width: 100%;

        display: flex;
        flex-direction: row;

        grid-column: span 2;
        grid-row: 3;
    }

    .qrd-section {
        grid-column: span 2;
        grid-row: 4;
    }

    .logo-container {
        display: none;
    }

    .qrd-section {
        width: 100%;
    }

    .mobile-hide {
        display: none;
    }

    .chat-leftpanel-button {
        display: flex !important;
    }

    .account-leftpanel-button {
        display: none !important;
    }
}

@media screen and (min-width: 1750px) {

    .scrolling-text {
        margin: 0px 12px;
        font-size: 76px;
        line-height: 28px;
    }

    .stream-box-container {
        width: 350px;
        height: 350px;
    }

    .group-grid {
        width: 350px;
        height: 350px;
    }

    .group-grid-default {
        width: 550px;
        height: 550px;
    }

    .colored-box {
        scale: 0.89;
    }

    .colored-border {
        scale: 0.86;
    }

    .stream-title-container, .group-stream-name {
        width: 67%;
    }

    .stream-title-container {
        transform: translateY(12px);
    }

    .group-stream-name {
        transform: translateY(4px);
    }

    .left-button-icon {
        width: 42px;
    }

    .chat-full-container {
        height: 79%;
    }

    .stream-status-light {
        transform: scale(2) translate(7px, 7px);
    }

    .type-image {
        transform: scale(4) translate(-5px, 3px);
    }

    .group-led-light {
        transform: scale(2) translate(3px, 2px);
    }

    .group-type-image {
        transform: scale(2) translate(-3px, 2px);
    }

    .tutorial-text {
        font-size: 30px;
    }

    .hazel {
        scale: 1.4;
    }
}

@media screen and (max-height: 550px) and (min-width: 700px) {
    .App {
        height: calc(200vh - 20px);
    }

    .mobile-hide {
        display: none;
    }
}