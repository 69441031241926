.control-app {
    height: calc(100vh - 20px);
    width: calc(100vw - 20px);
    display: grid;
    grid-template-columns: 10% 70% 20%;
    grid-template-rows: 10% 90%;
}

.control-status {
    border-color: #fff8ff #000 #000 #fff8ff;
    border-style: outset;
    border-width: 2px;
    outline: 2px solid #000;
    background-color: #d3d3d3;
}

.control-light-div {
    background-color: darkslategray;
    margin: 1px;
    border: outset 3px;
    outline: solid 1px black;
    cursor: pointer;
}

.control-light-div:hover {
    filter: brightness(1.1);
}

.control-light-div:active {
    border: inset 3px;
}

.control-desc-part {
    width: calc(100% - 12px);

    display: flex;
    align-items: center;
    justify-content: space-around;

    margin: 4px;
    border: black solid 2px;
    overflow: hidden;
}

.control-desc-part textarea {
    width: 100%;
    overflow: scroll;
}

.popup-desc {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: inset 3px;
    background-color: black;
    padding: 6px;
}

.control-desc-part button, .popup-desc button {
    font-family: blurpix;
    background-color: #d3d3d3;
    border-color: #fff8ff #000 #000 #fff8ff;
    border-style: outset;
    border-width: 2px;
    height: 25px;
    image-rendering: pixelated;
    outline: 1px solid #000;
    padding: 0 6px;
    cursor: pointer;
}

.popup-desc button {
    width: 65px;
    height: 50px;
    font-size: 12px;
}

.control-desc-part input, .control-desc-part textarea {
    border: inset 3px;
    outline: solid black 1px;
    background-color: darkslategray;
    height: 36px;
    font-size: 16px;
    color: white;
}

.control-desc {
    display: flex;
    flex-direction: column;
}



.control-account {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sync-notifier {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    border: inset 4px;
    outline: solid 1px black;
    outline-offset: -1px;
}

.led-light {
    border-radius: 50%;
    height: 16px;
    width: 16px;

    border-color: #212121 #000 #000 #212121;
    border-style: outset;
    border-width: 3px;

    outline: 2px solid #000;
    outline-offset: -4px;
}

.group-led-light {
    border-radius: 50%;
    height: 8px;
    width: 8px;
}

.group-grid-default .group-led-light {
    height: 16px;
    width: 16px;
}

.control-camera {
    margin: 15px;
    padding: 3px;
    background-color: #d3d3d3;
    border-color: #fff8ff #000 #000 #fff8ff;
    border-style: outset;
    border-width: 3px;
    outline: 2px solid #000;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.stream-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;

    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
}

.control-stream-box {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 15% 70% 15%;
    justify-items: center;

    margin: -1px 10px;
}

.stream-box-container {
    padding: 3px;

    background: url('/public/images/bgs/green_steel_container.png');
    background-size: cover;
    background-repeat: no-repeat;

    height: 190px;
    width: 190px;
}

.stream-box-container:hover {
    filter: brightness(1.1);
}

.stream-box-container:hover .stream-title, .group-stream-box:hover .stream-title {
    width: max-content;
    min-width: 100%;
    animation: titlehover 8s linear infinite forwards;
    overflow: visible;
    text-overflow: inherit;
}

.stream-box-container:hover .stream-title-container, .group-control-stream-box:hover .stream-title-container {
    background-color: rgba(255,255,255,0.1);
}

.handle {
    display: none;
}

.stream-box-container:hover .handle, .group-grid-default .group-control-stream-box:hover .handle, .featured-hover:hover .handle {
    display: flex;
}

.stream-box-0, .stream-box-1 {
    background-image: url('/public/images/bgs/green_steel_container.png');
}

.stream-box-0 .colored-box, .stream-box-1 .colored-box {
    border-color: #879987 #B6FFB6 #B6FFB6 #879987;
}

.stream-box-2 {
    background-image: url('/public/images/bgs/orange_steel_container.png') !important;
}

.stream-box-2 .colored-box {
    border-color: #999387 #ffeab6 #ffeab6 #999387;
}

@keyframes titlehover {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
    50% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    } 50.01% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
}

.stream-title {
    width: 100%; 
    margin: 0;
    text-align: center; 
    font-weight: bold;
    font-variant-caps: small-caps;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    text-shadow: 1.4px 1.4px 0px black;
}

.cc-control-stream-box {
    display: flex;
    flex-direction: column;
    justify-items: center;

    margin: 10px;
    padding: 4px;
    border: inset 3px;
    background-color: darkgray;

    width: 250px;

    outline: black 1px solid;
    outline-offset: 1px;
}

.group-container {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);
    justify-items: center;
    align-items: center;

    overflow: scroll;
    height: 100%;
}

.group-container-outer {
    height: 100%;
    min-height: 190px;
}

.group-stream-box {
    padding: 3px;
    width: 100%;
    height: 100%;
    scale: 0.9;

    background: url('/public/images/bgs/green_steel_container.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.group-control-stream-box {
    cursor: pointer; 
    align-items: stretch; 
    height: fit-content; 
    margin: 0;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 15% 70% 15%;
    justify-items: center;
}

.group-cam-title {
    font-size: 11px;
}

.group-grid {
    height: 190px;
    width: 190px;
    overflow-y: hidden;
}

.group-grid-default .group-container {
    overflow-y: scroll;
}

.group-container::-webkit-scrollbar {
    width: 4px;
    height: 100%;
}

.group-container::-webkit-scrollbar-thumb {
    background-color: lime;
    transition: 0.2s ease;
    border-radius: 15px;
    border: none;
}

.group-container::-webkit-scrollbar-track {
    background-color: black;
}

.type-image {
    width: 8px;
    height: 8px;
}

.group-grid-default {
    width: 380px;
    height: 380px;
}

.group-grid-default .group-cam-title {
    font-size: unset;
}

.group-grid-default .group-control-stream-box {
    margin: 0 10px;
}

.group-grid-default .group-stream-box {
    height: auto;
}

.group-grid-default .type-image {
    width: 16px;
    height: 16px;
}

.control-app h1, .control-app h2, .control-app h3, .control-app h4, .control-app p {
    font-family: ms ui gothic;
}

.control-app textarea, .control-app input, .control-app button, .control-app select, .control-app option {
    font-family: blurpix;
}

.analog-button {
    padding: 2px; 
    border: outset 3px; 
    outline: black solid 1px; 
    outline-offset: -1px;
}

.analog-button:hover {
    filter: brightness(1.1);
}

.analog-button:active {
    filter: brightness(0.9);
    border: inset 3px;
}

.analog-input {
    background-color: black;
    color: lime;
    border: inset 3px;
    outline: black solid 1px;
    outline-offset: -1px;
}

.invert-text {
    background-color: black;
    color: white;
    padding: 0 4px;
}

.square-button {
    width: 20px;
    height: 20px;
}


.status-indicators-control {
    border: inset 3px;
    background: black;
    padding: 5px;
}


.map-controls {
    grid-column: span 3;
    width: 100%;
    height: 500px;
}

.buttons-flex {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
}

.group-stream-name {
    margin: 0 8px;
}

.control-status {
    display: grid;
    grid-template-rows: 50% 50%;
}

.control-collapsed {
    height: 50px;
    overflow: hidden;
}

.control-accounts-wrapper {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border: inset 3px;
    padding: 2px;
    background: url('/public/images/bgs/moon-bg.png');
    overflow-y: scroll;
    overflow-x: hidden;
}

.p-control-grid {
    max-width: 100% !important;
}

@media screen and (max-width: 750px) {
    .buttons-flex {
        align-items: flex-start;
        justify-content: space-between;
    }
    .control-desc {
        flex-direction: column;
    }
    .control-app {
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .control-status {
        grid-template-rows: auto;
    }
    .control-account {
        grid-column: span 3;
        grid-row: 1;
    }
    .logo-container, .top-header-cc {
        display: none;
    }

    .control-accounts-wrapper {
        height: 240px;
    }

    .control-app .message-container-wrapper {
        height: 300px !important;
    }
}

@media screen and (min-width: 1750px) {
    .group-control-stream-box {
        padding: 0 3px;
    }
    
    .group-stream-name {
        margin: 0 12px;
    }

    .group-grid-default .group-stream-name {
        margin: 0 16px;
    }
}

.hover-bright:hover {
    filter: brightness(2);
}